<template>
  <div>
    <BRow>
      <BCol
          cols="12"
          md="6"
      >
        <FacilityInfoCard
          :item="data"
          :no-client="!!$route.query.clientId"
          @updateField="updateField"
        />
      </BCol>
      <BCol
          cols="12"
          md="6"
      >
        <FacilityPropsCard
          no-contacts
          :item="data"
          @updateField="updateField"
          @updateFields="updateFields"
        />
      </BCol>
    </BRow>
    <BRow class="mb-2">
      <BCol>
        <BButton
          class="mr-1"
          variant="primary"
          :disabled="buzy"
          @click="createEntity"
        >
          {{ t('dictionary.create') }}
        </BButton>
        <BButton
          variant="outline-primary"
          :disabled="buzy"
          @click="cancel"
        >
          {{ t('dictionary.cancel') }}
        </BButton>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {provide, ref, set} from '@vue/composition-api';
import { BRow, BCol, BButton } from 'bootstrap-vue';
import { useRouter } from '@core/utils/utils';
import FacilityInfoCard from '@/components/facilities/FacilityInfoCard.vue';
import FacilityPropsCard from '@/components/facilities/FacilityPropsCard.vue';
import EntityModel from '@/utils/EntityModel';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import {useToast} from "@/hooks/useToast";

export default {
  name: 'FacilityAdd',
  components: {
    FacilityInfoCard,
    FacilityPropsCard,
    BRow,
    BCol,
    BButton,
  },
  setup() {
    const { t } = useI18n();

    const data = ref({});
    const buzy = ref(false);
    const { updateFieldHandler, updateFieldsHandler, createEntityHandler } = EntityModel();
    const { router, route } = useRouter();
    const errors = ref([]);
    const { dangerToast } = useToast();

    provide('errors', errors);

    if (route.value.query.clientId) {
      data.value = {
        ...data.value,
        client_id: route.value.query.clientId,
      };
    }

    const updateField = async ({ field, value }) => {
      errors.value.splice(errors.value.indexOf(field), 1);
      buzy.value = true;
      if (field === 'manager') {
        data.value.manager = value;
        buzy.value = false;
        return;
      }
      data.value = await updateFieldHandler({
        action: null,
        entity: data.value,
        field,
        value,
      });
      buzy.value = false;
    };

    const updateFields = async ({ model, silent = false }) => {
      buzy.value = true;
      data.value = await updateFieldsHandler({
        model,
        silent,
        action: null,
        entity: data.value,
      });
      buzy.value = false;
    };

    const pushBackToClientPage = () => {
      router.push({
        name: 'client-view',
        params: {
          clientId: route.value.query.clientId,
        },
      });
    };

    const createEntity = async () => {
      buzy.value = true;
      errors.value = [];
      try {
        const {result, data: createdEntity} = await createEntityHandler({ action: 'facilities/createFacility', entity: data.value });
        if (result) {
          router.push({
            name: 'facility-view',
            params: {
              facilityId: createdEntity.id,
            },
          });
        } else {
          const responseErrors = Object.entries(createdEntity);
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of responseErrors) {
            errors.value.push(key);
            value.forEach((error) => {
              dangerToast(
                  t('errors.something-went-wrong.text'),
                  error.replace(`${key}`, t(`object.validation.${key}.replace`))
                    .replace(`${key.replace('_', ' ')}`, t(`object.validation.${key}.replace`)),
              );
            })
          }
        }
      } catch (e) {
        console.error(e);
      }
      buzy.value = false;
    };
    const cancel = () => {
      if (route.value.query.clientId) pushBackToClientPage();
      else {
        router.push({
          name: 'facilities',
        });
      }
    };

    const store = useStore();
    const setDefaults = async () => {
      const currentUser = store.state?.user?.data || {};
      const {
        avatar, email, firstname, id, patronymic, role, surname,
      } = currentUser;
      const manager = {
        avatar, email, firstname, id, patronymic, role, surname,
      };
      set(data.value, 'manager', manager);
      set(data.value, 'manager_id', id);
    };
    setDefaults();

    return {
      t,
      data,
      buzy,
      updateField,
      updateFields,
      createEntity,
      cancel,
    };
  },
};
</script>

<style lang="scss"></style>
