import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import errorsHelper from '@/utils/errorsHelper';

export default function () {
  const store = useStore();
  const { successToast, dangerToast } = useToast();
  const { t } = useI18n();
  const { getError } = errorsHelper();

  const updateFieldHandler = async ({
    action, entity, field, value,
  }) => {
    const model = {};
    model[field] = value;

    if (field === 'manager') {
      model.manager_id = value.id;
      model.manager = value;
    }

    if (entity?.id && action) {
      const { result, data } = await store.dispatch(action, {
        id: entity.id,
        model,
      });

      if (result) {
        successToast(
          t('settings.messageUpdated.title'),
          t('settings.messageUpdated.text'),
        );
      } else {
        dangerToast(
          t('errors.something-went-wrong.text'),
          getError(data.errors),
        );
      }
    }

    return { ...entity, ...model };
  };

  const updateFieldsHandler = async ({
    model, action, entity, silent,
  }) => {
    if (entity?.id && action) {
      const { result, data } = await store.dispatch(action, {
        id: entity.id,
        model,
      });

      if (result) {
        if (!silent) {
          successToast(
            t('settings.messageUpdated.title'),
            t('settings.messageUpdated.text'),
          );
        }
      } else {
        dangerToast(
          t('errors.something-went-wrong.text'),
          getError(data.errors),
        );
      }
    }

    return { ...entity, ...model };
  };

  const createEntityHandler = async ({ action, entity }) => {
    try {
      const { result, data } = await store.dispatch(action, entity);
      if (result) {
        successToast(
          t('dictionary.saved'),
        );
        return { result, data };
      }

      return { result, data: data.errors };
    } catch (e) {
      dangerToast(
        t('errors.something-went-wrong.text'),
        t('settings.updateFailedMessage.text'),
      );
    }

    return null;
  };

  return {
    updateFieldHandler,
    updateFieldsHandler,
    createEntityHandler,
  };
}
